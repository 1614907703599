<template>
  <div class="buttons-div">
    <div class="spacer-lg"></div>
    <div class="cont">
      <div class="register-btn-dv">
        <button class="reg-btn" @click="register()">REGISTER FOR DARSHAN</button>
      </div>
      <div class="search-div">
        <form @submit="search" class="search-form">
          <input type="text" placeholder="Search" class="txt-search" />
          <button class="sub-btn">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonsBar',
  methods: {
    register: () => {
      console.log("Register")
    },
    search: (e) => {
      e.preventDefault();
      console.log(e)
      const query = e.target[0].value;
      console.log(query);
      e.target[0].value = "";
      // Query Used later
    }
  },
}
</script>

<style>
.buttons-div {
  height: 7.5vh;
  width: 100%;
  display: flex;
}

.spacer-lg {
  width: 34.3%;
  height: inherit;
}

.cont {
  width: 48%;
  height: inherit;
  display: flex;
  flex-direction: row;

}

.register-btn-dv {
  width: 33%;
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.search-div {
  width: 67%;
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.txt-search {
  padding: 0.6rem;
  border-radius: 5px 0px 0px 5px;
  outline-color: #6F6F6F88;
  border: none;
  height: inherit;
  transition: outline-color 0.3s ease-in;
  font-family: 'Roboto', sans-serif;
}

.reg-btn {
  border-radius: 25px;
  background: var(--unnamed-color-d11b1b) 0% 0% no-repeat padding-box;
  background: #D11B1B 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  font-family: 'Roboto', sans-serif;
  color: white;
  padding: 0.8rem;
  font-weight: bold;
  box-shadow: 1px 2px #00000011;
}

.reg-btn:active {
  transform: translateY(1px);
  box-shadow: 0px 2px #00000011;
}

.sub-btn {
  background: var(--unnamed-color-d11b1b) 0% 0% no-repeat padding-box;
  background: #D11B1B 0% 0% no-repeat padding-box;
  border-radius: 0px 5px 5px 0px;
  opacity: 1;
  color: white;
  border: none;
  padding: 0.6rem;
}

@media screen and (max-width: 900px) {
  .buttons-div {
    height: 8vh;
  }

  .search-div {
    width: 90%;
  }

  .cont {
    width: 100%;
  }

  .reg-btn {
    width: 100%;
    font-size: 0.3rem;
  }

  .spacer-lg {
    width: 20%;
  }

  .txt-search {
    width: 50%;
  }

}
</style>
