<template>
  <a class="nav-item">
    HOME
  </a>
  <a class="nav-item" style="padding-right: 0; width: 12%; height: inherit;">
    ABOUT MANDIR
  </a>
  <a class="nav-item" style="align-items: flex-start; padding-right: 0;">
    BLOG
  </a>
  <a class="nav-item" style="padding-left: 0;">
    INVITE THAKUR JI
  </a>
  <a class="nav-item" style="padding-right: 0;">
    VISIT TEMPLE
  </a>
  <a class="nav-item">
    MEDIA
  </a>
  <a class="nav-item">
    DONATION
  </a>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>

<style>
.nav-item {
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 14%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  height: inherit;
  word-wrap: break-word;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.dropdown {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0.2rem;
}

.dropdown:hover {
  background-color: #EFA425;
}

.dropdown-content {
  padding-top: 1rem;
  position: relative;
}

@media screen and (max-width: 900px) {
  .nav-item {
    font-size: 0.3rem;
  }
}
</style>
