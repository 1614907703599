<template>
  <swiper :slides-per-view="1" :space-between="100" class="slider-box" :modules="modules" navigation
    :pagination="{ clickable: true }" :scrollbar="{ draggable: true }"
    :autoplay="{ 'delay': 3500, 'disableOnInteraction': false }">
    <swiper-slide class="slide1">
      <div class="slide-head">
        {{ this.slog }}
      </div>
      <div class="content-div-txt">
        <div class="head-1">
          {{ this.head }}
        </div>
        <div class="p1">
          {{ this.paraphr }}
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="slide1">
      <div class="slide-head">
        {{ this.slog }}
      </div>
      <div class="content-div-txt">
        <div class="head-1">
          {{ this.head }}
        </div>
        <div class="p1">
          {{ this.paraphr }}
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="slide1">
      <div class="slide-head">
        {{ this.slog }}
      </div>
      <div class="content-div-txt">
        <div class="head-1">
          {{ this.head }}
        </div>
        <div class="p1">
          {{ this.paraphr }}
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="slide1">
      <div class="slide-head">
        {{ this.slog }}
      </div>
      <div class="content-div-txt">
        <div class="head-1">
          {{ this.head }}
        </div>
        <div class="p1">
          {{ this.paraphr }}
        </div>
      </div>
    </swiper-slide>
    ...
  </swiper>
</template>

<script>
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slog: "नमः कुञ्ज बिहारिण्यै, नमः कुञ्ज बिहारिणे। स्वामी श्री हरिदासाय, गुरूणां गुरुवे नमः।।"
      , head: "प्रेम समुद्र रूप रस गहरे, कैसे पाऊँ थाह ||"
      , paraphr: "Lord Shri Banke Bihariji Maharaj are the ocean of love, the cosmic␃beauty and eternal bliss of this ocean is so deep that nobody can␃measure it."

    }
  },
  setup() {
    return {
      modules: [Navigation, Pagination, A11y, Autoplay],
    };
  },
};
</script>

<style>
.slider-box {
  display: flex;
  width: 80%;
  height: 95%;
  border-radius: 20px;
  background-color: #000000ee;
  box-shadow: 0px 0px 40px 20px #00000055;
}

.content-div-txt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3rem;
}

.head-1 {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.swiper-button-next,
.swiper-button-prev {
  color: #d11b1b;
}

.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #d11b1b;
}

.slide1 {
  width: 100% !important;
  height: 100%;
  background-image: url('../assets/carousel2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.slide-head {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 1;
  font-weight: bold;
  letter-spacing: 0.015rem;
  font-size: 1.3rem;
}

.p1 {
  font-size: 1.2rem;
  color: white;
  font-size: 1.3rem;
  margin-top: 2rem;
  width: 80%;
  font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 900px) {
  .slide-head {
    margin-left: 1rem;
  }
}
</style>
