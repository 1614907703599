<template>
  <div class="home-box">
    <div class="header">
      <div class="spacer"></div>
      <div class="nav-section">
        <NavBar />
      </div>
    </div>
    <ButtonsBar />
    <div class="home-page-car">
      <CarouSel />
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import ButtonsBar from './ButtonsBar.vue';
import CarouSel from './CarouSel.vue';

export default {
  name: 'HomePage',
  data() {
    return {
      headTitle: "Shri Banke Bihari Mandir",
      subText: "Vrindavan",
      slog: "श्रीमत कुंज बिहारिने नमः"
    }
  },
  components: {
    NavBar,
    ButtonsBar,
    CarouSel
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi:ital@1&display=swap');

.home-box {
  height: 100vh;
  display: flex;
  background-image: url('../assets/header.png');
  background-size: 100%;
  background-repeat: no-repeat;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  height: 33vh;
  width: 100vw;
  justify-content: flex-start;
  align-items: flex-end;
}

.spacer {
  display: flex;
  width: 31%;
  height: inherit;
}

.nav-section {
  display: flex;
  width: 52%;
  height: 11.5vh;
  flex-direction: row;
}

.home-page-car {
  width: 100%;
  height: 67vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-height: 950px) and (min-height: 800px) and (max-width: 900px) {
  .header {
    height: 12vh;
  }
}

@media screen and (max-height: 690px) and (min-height: 300px) and (max-width: 900px) {
  .header {
    height: 13.6vh;
  }
}
</style>
