<template>
  <section id="landing">
    <HomePage />
  </section>
</template>

<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',

  components: {
    HomePage
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(circle, #F6DB4B, #F18306);
  margin: 0 auto;
}

body {
  margin: 0 auto;
}

@font-face {
  font-family: 'Saman';
  src: url('./fonts/SAMAN___.TTF');
}
</style>
